import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { AppProvider } from './providers'
import { environment } from '@/constants'

Sentry.init({
  dsn: environment.sentry.DSN,
  integrations: [Sentry.browserTracingIntegration()],
  environment: environment.app.MODE,
  tracesSampleRate: 1.0,
})

const container = document.body.appendChild(document.createElement('div'))
const root = createRoot(container)
root.render(<AppProvider />)
