/** 環境変数 */
export type Environment = {
  app: {
    /** 動作モード('production'|'staging'|'development') */
    MODE: string
  }
  api: {
    /** 基底URL */
    BASE: string
    /** サブドメインを適用するか */
    ENFORCE_SUBDOMAIN: string
  }
  sentry: {
    /** データソース名 */
    DSN: string
  }
}

/** 環境変数の値 */
export const environmentValue = {
  /** 有効 */
  ENABLE: '1',
  /** 無効 */
  DISABLE: '0',
} as const

export const environment: Environment = {
  app: {
    MODE: process.env.REACT_SENTRY_ENV || 'development',
  },
  api: {
    BASE: process.env.ELNAVI_APP_API_BASE || window.location.origin,
    ENFORCE_SUBDOMAIN:
      process.env.ELNAVI_APP_API_ENFORCE_SUBDOMAIN || environmentValue.DISABLE,
  },
  sentry: {
    DSN: process.env.REACT_SENTRY_DSN || '',
  },
} as const
