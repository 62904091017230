import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ImportErrorResponse, Pagination } from '../../interfaces/api'
import {
  Overwork,
  OverworkSetting,
  OverworkSurvey,
  OverworkSurveyResult,
} from '../../interfaces/healthCare/overwork'
import {
  OverworkData,
  OverworksData,
  OverworkSurveyData,
  OverworkSurveyResultData,
  OverworkSurveysData,
} from '@/repositories/healthCare/overwork'
import { OverworkSettingData } from '@/repositories/healthCare/setting/overwork'

interface State {
  overworkSetting: OverworkSetting | null
  overworks: Overwork[] | null
  overwork: Overwork | null
  overworkSurveys: OverworkSurvey[] | null
  overworkSurveyResult: OverworkSurveyResult | null

  paginationOverworks: Pagination | null
  paginationOverworkSurveys: Pagination | null

  waitingOverworkSetting: boolean
  waitingOverworks: boolean
  waitingOverworkSurvey: boolean
  waitingOverworkSurveys: boolean
  waitingOverworkSurveysSendMail: boolean
  waitingOverworkSurveyResult: boolean

  importErrorSurveys: ImportErrorResponse[] | null
}

const initialState: State = {
  overworkSetting: null,
  overworks: null,
  overwork: null,
  overworkSurveys: null,
  overworkSurveyResult: null,
  paginationOverworks: null,
  paginationOverworkSurveys: null,
  waitingOverworkSetting: false,
  waitingOverworks: false,
  waitingOverworkSurvey: false,
  waitingOverworkSurveys: false,
  waitingOverworkSurveysSendMail: false,
  waitingOverworkSurveyResult: false,
  importErrorSurveys: null,
}

const overwork = createSlice({
  name: 'overwork',
  initialState,
  reducers: {
    setWaitingOverworkSetting: (state) => {
      state.waitingOverworkSetting = true
    },
    setWaitingOverworks: (state) => {
      state.waitingOverworks = true
    },
    setWaitingOverworkSurvey: (state) => {
      state.waitingOverworkSurvey = true
    },
    setWaitingOverworkSurveys: (state) => {
      state.waitingOverworkSurveys = true
    },
    setWaitingOverworkSurveysSendMail: (state) => {
      state.waitingOverworkSurveysSendMail = true
    },
    setWaitingOverworkSurveyResult: (state) => {
      state.waitingOverworkSurveyResult = true
    },
    setOverworkSetting: (state, action: PayloadAction<OverworkSettingData>) => {
      state.overworkSetting = action.payload.overworkSetting
    },
    setOverworks: (state, action: PayloadAction<OverworksData>) => {
      state.overworks = action.payload.overworks
    },
    setOverwork: (state, action: PayloadAction<OverworkData>) => {
      state.overwork = action.payload.overwork
    },
    setOverworkSurvey: (state, action: PayloadAction<OverworkSurveyData>) => {
      const updateSurvey = action.payload.overworkSurvey
      if (state.overworkSurveys) {
        state.overworkSurveys = state.overworkSurveys.map((survey) =>
          survey.id === updateSurvey.id ? updateSurvey : survey,
        )
      }
    },
    setOverworkSurveys: (state, action: PayloadAction<OverworkSurveysData>) => {
      state.overworkSurveys = action.payload.overworkSurveys
    },
    setOverworkSurveyResult: (
      state,
      action: PayloadAction<OverworkSurveyResultData>,
    ) => {
      state.overworkSurveyResult = action.payload.overworkSurvey
    },
    setPaginationOverworks: (
      state,
      action: PayloadAction<Pagination | null>,
    ) => {
      state.paginationOverworks = action.payload
    },
    setPaginationOverworkSurveys: (
      state,
      action: PayloadAction<Pagination | null>,
    ) => {
      state.paginationOverworkSurveys = action.payload
    },
    setImportErrorSurveys: (
      state,
      action: PayloadAction<{ errors: ImportErrorResponse[] }>,
    ) => {
      state.importErrorSurveys = action.payload.errors
    },
    resetOverworkSurveys: (state) => {
      state.overworkSurveys = initialState.overworkSurveys
    },
    resetOverworkSurveyResult: (state) => {
      state.overworkSurveyResult = initialState.overworkSurveyResult
    },
    resetWaitingOverworkSetting: (state) => {
      state.waitingOverworkSetting = initialState.waitingOverworkSetting
    },
    resetWaitingOverworks: (state) => {
      state.waitingOverworks = initialState.waitingOverworks
    },
    resetWaitingOverworkSurvey: (state) => {
      state.waitingOverworkSurvey = initialState.waitingOverworkSurvey
    },
    resetWaitingOverworkSurveys: (state) => {
      state.waitingOverworkSurveys = initialState.waitingOverworkSurveys
    },
    resetWaitingOverworksSendMail: (state) => {
      state.waitingOverworkSurveysSendMail =
        initialState.waitingOverworkSurveysSendMail
    },
    resetWaitingOverworkSurveyResult: (state) => {
      state.waitingOverworkSurveyResult =
        initialState.waitingOverworkSurveyResult
    },
    resetImportErrorSurveys: (state) => {
      state.importErrorSurveys = initialState.importErrorSurveys
    },
  },
})

export const actions = overwork.actions
export default overwork.reducer
