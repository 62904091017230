import { AppDispatch, store } from '../../stores'
import { ApiUserRoles } from '@/repositories/userRole'
import { checkResponseDataStatus } from '../api'
import { actions } from '@/stores/auth'
import { enqueueErrorSnackbar } from '../snackbars'
import { UserRole } from '../../interfaces/userRole'
import { menuDetailNavigations } from '../../pages/app/Menus'
import { getUserId } from '../../utils/userStorage'
import { updateUser } from './user'
import { resetDepartments } from '../setting/departments'
import { resetBranches } from '../setting/branches'
import { resetEmployeeTitles } from '../setting/employeeTitles'
import { resetEmployeeStatuses } from '../setting/employeeStatuses'
import { resetEmployee, resetEmployees } from '../setting/employees'
import { resetEmployeeGenders } from '../setting/employeeGenders'

/**
 * ユーザー権限一覧の取得
 *
 * ユーザー権限一覧を取得して store に格納する。
 * ただし、すでに一覧が存在する場合には取得せず、取得済のオブジェクトを返却する。
 *
 * @param force 強制的に再読み込み
 */
const fetchUserRoles =
  (force?: boolean) =>
  (dispatch: AppDispatch): Promise<UserRole[] | void> => {
    const userRoles = store.getState().auth.userRoles
    if (userRoles?.length && !force) {
      return Promise.resolve(userRoles)
    }
    return ApiUserRoles()
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.setUserRoles(data))
        return data.userRoles
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const getUserTopPage =
  (userRoleId?: number | null) =>
  async (dispatch: AppDispatch): Promise<string> => {
    const userRoles = await dispatch(fetchUserRoles())

    if (!userRoles) {
      return ''
    }

    let topMenuId: number

    if (userRoleId) {
      // 指定したユーザーロールID おいて menus[] の先頭に定義されているメニューをトップページとする
      const userRole = userRoles.find((userRole) => userRole.id === userRoleId)
      topMenuId = userRole
        ? userRole?.menuDetails[0].menuId
        : userRoles[0].menuDetails[0].menuId
    } else {
      topMenuId = userRoles[0].menus[0].menuId
    }

    const topMenu = menuDetailNavigations.find(
      (menuNavi) => menuNavi.id === topMenuId,
    )

    const path = topMenu?.link ?? '/'
    dispatch(actions.setTopPage(path))
    return path
  }

export const updateCurrentRole =
  (currentUserRoleId: number) =>
  (dispatch: AppDispatch): Promise<void> => {
    /* 設定関連のデータをクリア */
    dispatch(resetSetting())

    const id = getUserId()
    if (id) {
      const params = { id, currentUserRoleId }
      return dispatch(updateUser(params))
    } else {
      return Promise.resolve()
    }
  }

/**
 * 現在の権限を取得
 *
 * 現在操作している権限オブジェクトを取得する。
 *
 * @param userRoles
 * @param currentUserRoleId
 */
export const getCurrentRole = (
  userRoles: UserRole[] | null,
  currentUserRoleId: number | undefined | null,
): UserRole | undefined =>
  userRoles?.find((userRole) => userRole.id === currentUserRoleId)

/**
 * メニューIDの取得
 *
 * 現在選択されているユーザー権限で利用可能なメニューIDの配列を返却する
 * @param userRoles
 * @param selectUserRoleId
 */
export const getUserRoleMenuIds = (
  userRoles: UserRole[] | null,
  selectUserRoleId: number | undefined | null,
): number[] | undefined => {
  return !userRoles || !selectUserRoleId
    ? undefined
    : userRoles
        ?.find((userRole) => selectUserRoleId === userRole.id)
        ?.menus.map((userRoleMenu) => userRoleMenu.menuId)
}

export const resetStore =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    dispatch(resetSetting())
    return dispatch(actions.reset())
  }

const resetSetting =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(resetBranches())
    dispatch(resetDepartments())
    dispatch(resetEmployeeTitles())
    dispatch(resetEmployeeGenders())
    dispatch(resetEmployeeStatuses())
    dispatch(resetEmployees())
    dispatch(resetEmployee())
  }
