import { useState } from 'react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  Avatar,
  Box,
  Button,
  Container,
  ContainerProps,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { Lock, RecentActors } from '@mui/icons-material'
import { isLoginEmail } from '../../utils/validations'
import { ApiForgotPassword } from '@/repositories/session'
import { Copyright } from './Copyright'

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState({ disp: false, message: '' })
  const [disabled, setDisabled] = useState(false)

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentStr = event.currentTarget.value
    setEmail(currentStr)
  }

  const handleValidateEmail = () => {
    if (email && !isLoginEmail(email)) {
      setValidEmail({ disp: true, message: 'Emailを正しく入力してください' })
    } else {
      setValidEmail({ disp: false, message: '' })
    }
  }

  const handleLogin = () => {
    navigate('../login')
  }

  const handleForgotPasswordSubmit = () => {
    if (isLoginEmail(email)) {
      ApiForgotPassword({ email: email }).then((response) => {
        try {
          if (response.status === 'success') {
            setDisabled(true)
            return enqueueSnackbar('ログインリンクを送信しました', {
              variant: 'success',
              key: 'forgotPassword',
            })
          }
          if (response.status === 'error') {
            setDisabled(true)
            return enqueueSnackbar(response.message, {
              variant: 'success',
              key: 'forgotPassword',
            })
          }
        } catch (e) {
          return enqueueSnackbar('ログインリンクの送信に失敗しました', {
            variant: 'error',
            key: 'forgotPassword',
          })
        }
      })
    } else {
      return enqueueSnackbar('Emailを正しく入力してください', {
        variant: 'error',
        key: 'forgotPassword',
      })
    }
  }

  return (
    <React.Fragment>
      <ContainerStyled component="main" maxWidth="xs">
        <Paper>
          <AvatarStyled>
            <Lock fontSize="large" />
          </AvatarStyled>

          <Paper>
            <Typography component="h6" variant="h6">
              ログインできない場合
            </Typography>
          </Paper>

          <Paper>
            <Typography variant="body2" color="textSecondary" align="center">
              アカウントを再開するためのリンクを送信します。
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              リンクを受け取るメールアドレスを入力してください。
            </Typography>
          </Paper>

          <TextField
            id="emailtext"
            required
            variant="outlined"
            label="メールアドレス"
            disabled={disabled}
            value={email}
            onChange={handleChangeEmail}
            onBlur={handleValidateEmail}
            margin="normal"
            name="email"
            autoComplete="email"
            autoFocus
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RecentActorsStyled />
                </InputAdornment>
              ),
            }}
            error={validEmail.disp}
            helperText={validEmail.message}
          />

          <ButtonStyled
            variant="contained"
            fullWidth
            color="primary"
            size="large"
            disabled={disabled}
            onClick={handleForgotPasswordSubmit}
          >
            ログインリンクを送信
          </ButtonStyled>
        </Paper>

        <Paper>
          <Button color="primary" onClick={handleLogin}>
            ログイン画面へ
          </Button>
        </Paper>

        <Box mt={8}>
          <Copyright />
        </Box>
      </ContainerStyled>
    </React.Fragment>
  )
}

const ContainerStyled = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(20),
}))

const Paper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}))

const RecentActorsStyled = styled(RecentActors)(({ theme }) => ({
  fontSize: 20,
  marginLeft: theme.spacing(1),
  opacity: 0.8,
}))

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(7),
  backgroundColor: theme.palette.secondary.main,
}))
