import { AppDispatch, store } from '../../stores'
import {
  BranchesData,
  getBranches,
  getBranchList,
  BranchesParams,
  postBranch,
  putBranch,
  deleteBranch,
  importResults,
  exportBranchResults,
  getStressCheckBranchOptions,
} from '@/repositories/setting'
import { actions } from '@/stores/setting'
import { enqueueErrorSnackbar, enqueueSnackbar } from '../snackbars'
import {
  checkResponseDataStatus,
  checkResponseStatus,
  downloadFile,
} from '../api'
import { Branch } from '../../interfaces/branch'
import { CSVValidateRecord } from '../csvUpload'
/**
 * 事業所一覧の取得
 *
 * 事業所一覧を取得して store に格納する。
 * ただし、すでに一覧が存在する場合には取得しない。
 */
export const fetchBranches =
  () =>
  (
    dispatch: AppDispatch,
  ): Promise<{ payload: BranchesData; type: string } | void> => {
    const branches = store.getState().setting.branches
    if (branches?.length) {
      return Promise.resolve()
    }

    dispatch(actions.setBranchWaiting())
    return getBranches()
      .then((response) => {
        if (response.status === 'error') {
          throw new Error(response.message)
        } else {
          return response.data
        }
      })
      .then((data) => dispatch(actions.setBranches(data)))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchBranchList =
  (params?: BranchesParams) => (dispatch: AppDispatch) => {
    dispatch(actions.setBranchWaiting())
    return getBranchList(params)
      .then(checkResponseStatus)
      .then((response) => {
        dispatch(actions.setBranches(response.data))
        dispatch(
          actions.setPaginationBranches({ pagination: response.meta ?? null }),
        )
        return {
          branches: response.data.branches,
          pagination: response.meta ?? null,
        }
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const createBranch =
  (branch: Branch, callback?: () => void) => (dispatch: AppDispatch) => {
    /* プロパティから id を除去 */
    const params = (({ id: _id, ...rest }) => rest)(branch)
    return postBranch(params)
      .then(checkResponseDataStatus)
      .then(() => {
        dispatch(enqueueSnackbar('事業所情報を登録しました'))
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const updateBranch =
  (branch: Branch, callback?: () => void) => (dispatch: AppDispatch) =>
    putBranch(branch)
      .then(checkResponseStatus)
      .then(() => {
        dispatch(enqueueSnackbar('事業所情報を更新しました'))
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })

export const removeBranch =
  (branchId: number, callback?: () => void) => (dispatch: AppDispatch) =>
    deleteBranch({ id: branchId })
      .then(checkResponseStatus)
      .then(() => {
        dispatch(enqueueSnackbar('事業所情報を削除しました'))
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })

export const getBranchName = (branchId: number | undefined): string => {
  if (branchId === undefined) {
    return ''
  }
  const { branches } = store.getState().setting
  return branches?.find((branch) => branch.id === branchId)?.name ?? ''
}

export const resetBranches =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.resetBranches())
  }

export const resetBranch =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.resetBranch())
  }

export const uploadResults =
  (params: { file: File }, callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingUploadCsv())
    return importResults(params)
      .then((response) => {
        if (response.status === 'error') {
          response.errors &&
            dispatch(
              actions.setErrorResponseResults({ errors: response.errors }),
            )
          throw new Error(response.message)
        } else {
          dispatch(enqueueSnackbar(`${response.message}`))
          return response.message
        }
      })
      .then(() => callback && callback())
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingUploadCsv())
      })
  }

export const downloadResults =
  (fileName?: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingDownloadCsv())
    return exportBranchResults()
      .then((response) => downloadFile(response, fileName))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingDownloadCsv())
      })
  }

export const resetErrorResponseResult =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(actions.resetErrorResponseResults())
  }

export const fetchStressCheckBranchOptions =
  (stressCheckId: number | string) =>
  (
    dispatch: AppDispatch,
  ): Promise<{ payload: BranchesData; type: string } | void> => {
    dispatch(actions.setBranchWaiting())
    return getStressCheckBranchOptions(stressCheckId)
      .then((response) => {
        if (response.status === 'error') {
          throw new Error(response.message)
        } else {
          return response.data
        }
      })
      .then((data) => dispatch(actions.setBranches(data)))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

type EmployeeRecordPick = Pick<Branch, 'code' | 'name'>

type ResultRecord = { [P in keyof EmployeeRecordPick]: string }

export const resultsCsvHeaders: CSVValidateRecord<ResultRecord>[] = [
  { index: 0, name: 'code', label: '事業所ID' },
  { index: 1, name: 'name', label: '事業所名', nullable: true },
]
