import { AppDispatch, store } from '../../stores'
import {
  EmployeeStatusesData,
  getEmployeeStatuses,
} from '@/repositories/setting'
import { actions } from '@/stores/setting'
import { enqueueErrorSnackbar } from '../snackbars'

/**
 * 就業ステータス一覧の取得
 *
 * 就業ステータス一覧を取得して store に格納する。
 * ただし、すでに一覧が存在する場合には取得しない。
 */
export const fetchEmployeeStatuses =
  () =>
  (
    dispatch: AppDispatch,
  ): Promise<{ payload: EmployeeStatusesData; type: string } | void> => {
    const employeeStatuses = store.getState().setting.employeeStatuses
    if (employeeStatuses?.length) {
      return Promise.resolve()
    }

    dispatch(actions.setEmployeeStatusWaiting())
    return getEmployeeStatuses()
      .then((response) => {
        if (response.status === 'error') {
          throw new Error(response.message)
        } else {
          return response.data
        }
      })
      .then((data) => dispatch(actions.setEmployeeStatus(data)))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const resetEmployeeStatuses =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.resetEmployeeStatuses())
  }
