import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WellnessLiteEvent } from '../interfaces/detection'
import {
  WellnessLiteEventsData,
  WellnessLiteEventNames,
} from '@/repositories/detection/wellnessLite'
import { Pagination } from '../interfaces/api'

interface State {
  wellnessLiteEvents: WellnessLiteEvent[] | null
  wellnessLiteEventNames: WellnessLiteEventNames | null
  paginationWellnessLiteEvents: Pagination | null
  waitingWellnessLiteEvents: boolean
}

const initialState: State = {
  wellnessLiteEvents: null,
  wellnessLiteEventNames: null,
  paginationWellnessLiteEvents: null,
  waitingWellnessLiteEvents: false,
}

const wellnessLite = createSlice({
  name: 'wellnessLite',
  initialState,
  reducers: {
    setWaitingWellnessLiteEvents: (state) => {
      state.waitingWellnessLiteEvents = true
    },
    setWellnessLiteEvents: (
      state,
      action: PayloadAction<WellnessLiteEventsData>,
    ): void => {
      state.wellnessLiteEvents = action.payload.wlEvents
      state.waitingWellnessLiteEvents = false
    },
    setWellnessLiteEventNames: (
      state,
      action: PayloadAction<WellnessLiteEventNames>,
    ): void => {
      state.wellnessLiteEventNames = action.payload
    },
    setPaginationWellnessLiteEvents: (
      state,
      action: PayloadAction<{ pagination: Pagination | null }>,
    ): void => {
      state.paginationWellnessLiteEvents = action.payload.pagination
    },
    resetWaitingWellnessLiteEvents: (state) => {
      state.waitingWellnessLiteEvents = initialState.waitingWellnessLiteEvents
    },
  },
})

export const actions = wellnessLite.actions
export default wellnessLite.reducer
