import {
  CheckupFinalResultEmployeesAllResponse,
  CheckupFinalResultEmployeesParams,
} from '@/repositories/healthCare/checkup'
import { useFetch } from '../../../hooks/State'

export type UseCheckUpFinalResultEmployeesAllProps = {
  fiscalYearId: number | string | undefined
  params?: CheckupFinalResultEmployeesParams | undefined
}

/**
 * 最終判定全対象者一覧の取得
 *
 * @param fiscalYearId 年ID
 * @param params 抽出条件オブジェクト
 */
export const useFetchCheckUpFinalResultEmployeesAll = ({
  fiscalYearId,
  params,
}: UseCheckUpFinalResultEmployeesAllProps) => {
  const { data, isLoading, isValidating, mutate } =
    useFetch<CheckupFinalResultEmployeesAllResponse>(
      getKey({ fiscalYearId, params }),
    )

  return {
    checkupSurveysAll: data?.data.checkupSurveys,
    isLoading,
    isValidating,
    mutate,
  }
}

/**
 * キーの取得
 *
 * もし抽出に必要な fiscalYearId が未定義の場合は false を返し、
 * データを取得しないようにする。
 */
const getKey = ({
  fiscalYearId,
  params: query,
}: UseCheckUpFinalResultEmployeesAllProps) =>
  fiscalYearId === undefined
    ? false
    : {
        url: `/api/v1/checkup/final_results/years/${fiscalYearId}/all`,
        query,
      }
