import { AppDispatch, store } from '../../../stores'
import {
  deleteEvent,
  EventData,
  EventParams,
  EventsParams,
  getEvent,
  getEvents,
  patchEvent,
  postEvent,
} from '@/repositories/healthCare/checkup'
import { actions } from '@/stores/healthCare/checkup'
import { enqueueErrorSnackbar, enqueueSnackbar } from '../../snackbars'
import { Prop } from '../../../interfaces/entity'
import { ValidateError } from '../../../interfaces/validation'
import { validateErrorControl } from '../../validations'
import { FiscalYear } from '../../../interfaces/healthCare/checkup'
import {
  endDayOfFiscalYear,
  isSameOrAfterDate,
  startDayOfFiscalYear,
} from '../../../utils/dates'
import { isEmptyValue } from '../../../utils/validations'
import { getUserId } from '../../../utils/userStorage'
import { checkResponseDataStatus, checkResponseStatus } from '../../api'

export const fetchEvents =
  (fiscalYearId: number | undefined, params?: EventsParams) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!fiscalYearId) {
      return Promise.resolve()
    }
    dispatch(actions.setWaitingEvents())
    return getEvents(fiscalYearId, params)
      .then(checkResponseStatus)
      .then((response) => {
        dispatch(actions.setEvents(response.data))
        dispatch(
          actions.setPaginationEvents({ pagination: response.meta ?? null }),
        )
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchEvent =
  (eventId: string | undefined) =>
  (
    dispatch: AppDispatch,
  ): Promise<{ payload: EventData; type: string } | void> => {
    if (!eventId) {
      return Promise.resolve()
    }
    dispatch(actions.setWaitingEvents())
    return getEvent(eventId)
      .then(checkResponseDataStatus)
      .then((data) => dispatch(actions.setEvent(data)))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const setInitialEvent =
  (fiscalYear: FiscalYear | null, eventEdit: EventParams | null) =>
  (dispatch: AppDispatch): void => {
    if (fiscalYear === null) {
      return
    }
    const userId = getUserId()
    if (!userId) {
      return
    }
    eventEdit = {
      ...(eventEdit || ({} as EventParams)),
      ...getDefaultDate(fiscalYear.fiscalYear),
      fiscalYearId: fiscalYear.id,
      userId,
    }
    dispatch(actions.setEventEdit({ eventEdit }))
  }

export const changeEvent =
  (
    key: keyof EventParams,
    value: string | number,
    fiscalYear: number | undefined,
  ) =>
  (dispatch: AppDispatch): void => {
    if (fiscalYear === undefined) {
      return
    }
    const { validateEventErrors, eventEdit } = store.getState().checkup
    const changeErrors = validate(
      { key, value },
      validateEventErrors,
      fiscalYear,
      eventEdit,
    )
    dispatch(actions.setValidateEventErrors(changeErrors))
    dispatch(actions.changeEvent({ key, value }))
  }

export const createEvent =
  (
    params: EventParams | null,
    fiscalYear: number | undefined,
    callback?: (eventData: EventData) => void,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (params === null || fiscalYear === undefined) {
      return Promise.resolve()
    }
    const errors = validateAll(params, fiscalYear)
    if (errors.length) {
      dispatch(actions.setValidateEventErrors(errors))
      return Promise.resolve()
    }

    dispatch(actions.setWaitingEvents())
    return postEvent(params)
      .then(checkResponseDataStatus)
      .then((result) => {
        dispatch(enqueueSnackbar('健康診断の登録が完了しました'))
        callback && callback(result)
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const updateEvent =
  (
    eventId: string | undefined,
    params: EventParams | null,
    fiscalYear: number | undefined,
    callback?: () => void,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!eventId || params === null || fiscalYear === undefined) {
      return Promise.resolve()
    }

    const errors = validateAll(params, fiscalYear)
    if (errors.length) {
      dispatch(actions.setValidateEventErrors(errors))
      return Promise.resolve()
    }

    dispatch(actions.setWaitingEvents())

    return patchEvent(eventId, params)
      .then(checkResponseDataStatus)
      .then((data) => dispatch(actions.setEvent(data)))
      .then(() => dispatch(enqueueSnackbar('健康診断の更新が完了しました')))
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const removeEvent =
  (eventId: string | undefined, callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (eventId === undefined) {
      return Promise.resolve()
    }
    return deleteEvent(eventId)
      .then(checkResponseStatus)
      .then(() => dispatch(enqueueSnackbar('健康診断の削除が完了しました')))
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const resetEvent =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(actions.resetEvent())
    dispatch(actions.resetValidateEventErrors())
  }

export const resetEventEdit =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(actions.resetEventEdit())
    dispatch(actions.resetValidateEventErrors())
  }

const getDefaultDate = (
  selectedYear: number,
): { startAt: string; endAt: string } => {
  return {
    startAt: startDayOfFiscalYear(selectedYear),
    endAt: endDayOfFiscalYear(selectedYear),
  }
}

const validateAll = (
  event: EventParams,
  fiscalYear: number,
): ValidateError<EventParams>[] => {
  const props: (keyof EventParams)[] = ['name', 'typeId', 'startAt', 'endAt']
  let errors = store.getState().checkup.validateEventErrors
  props.forEach((key) => {
    errors = validate(
      { key, value: event && event[key] },
      errors,
      fiscalYear,
      event,
    )
  })
  return errors
}

const validate = (
  prop: Prop<EventParams>,
  errors: ValidateError<EventParams>[],
  fiscalYear: number,
  event: EventParams | null,
): ValidateError<EventParams>[] => {
  const validateErrors = validateErrorControl<EventParams>(errors)
  validateErrors.remove(prop.key)

  if (isEmptyValue(prop.value)) {
    validateErrors.add({
      item: prop.key,
      message: `:${prop.key}は必ず入力してください`,
    })
    return validateErrors.values()
  }

  if (prop.key === 'startAt') {
    const startAt = startDayOfFiscalYear(fiscalYear)
    !isSameOrAfterDate(startAt, prop.value as string) &&
      validateErrors.add({
        item: prop.key,
        message: `:${prop.key}は ${startAt} 以降を入力してください`,
      })
    if (!isSameOrAfterDate(startAt, prop.value as string)) {
      validateErrors.add({
        item: prop.key,
        message: `:${prop.key}は ${startAt} 以降を入力してください`,
      })
    } else if (
      event?.endAt &&
      !isSameOrAfterDate(prop.value as string, event.endAt)
    ) {
      validateErrors.add({
        item: prop.key,
        message: `:${prop.key}は終了日以前を入力してください`,
      })
    }
  }

  if (prop.key === 'endAt') {
    const endAt = endDayOfFiscalYear(fiscalYear)
    if (!isSameOrAfterDate(prop.value as string, endAt)) {
      validateErrors.add({
        item: prop.key,
        message: `:${prop.key}は ${endAt} 以前を入力してください`,
      })
    } else if (
      event?.startAt &&
      !isSameOrAfterDate(event.startAt, prop.value as string)
    ) {
      validateErrors.add({
        item: prop.key,
        message: `:${prop.key}は開始日以降を入力してください`,
      })
    }
  }

  return validateErrors.values()
}
