import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { InterviewTemplate } from '../../../interfaces/shareNavi/interview'
import { RootState } from '../../index'
import {
  getInterviewTemplates,
  InterviewTemplatesData,
} from '@/repositories/shareNavi/interview'
import { checkResponseDataStatus } from '../../../services/api'

const adapter = createEntityAdapter<InterviewTemplate>()

export const fetchInterviewTemplatesAction = createAsyncThunk<
  InterviewTemplatesData,
  void,
  { state: RootState }
>(
  'interviewTemplates/fetchInterviewTemplates',
  async (_, { rejectWithValue }) => {
    try {
      return getInterviewTemplates().then(checkResponseDataStatus)
    } catch (error) {
      return error instanceof Error
        ? rejectWithValue({ message: error.message })
        : rejectWithValue({
            message: '面談テンプレート一覧の取得に失敗しました',
          })
    }
  },
  {
    condition: (_, { getState }) =>
      selectInterviewTemplatesTotal(getState()) === 0,
  },
)

const interviewTemplatesSlice = createSlice({
  name: 'interviewTemplates',
  initialState: adapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchInterviewTemplatesAction.fulfilled,
      (state, action) => {
        adapter.setAll(state, action.payload.templates)
      },
    )
  },
})

export const {
  selectAll: selectInterviewTemplates,
  selectTotal: selectInterviewTemplatesTotal,
} = adapter.getSelectors(
  (state: RootState) => state.interview.interviewTemplates,
)
export default interviewTemplatesSlice.reducer
