import { Interview } from '../../../interfaces/shareNavi/interview'
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../index'
import { StoreStatus } from '../../../interfaces/api'
import {
  deleteInterview,
  getInterview,
  InterviewParams,
  patchInterview,
  postInterview,
  InterviewSendMailParams,
  sendMailInterview,
} from '@/repositories/shareNavi/interview'
import { checkResponseDataStatus } from '../../../services/api'

interface State {
  entity: Interview | null
  status: StoreStatus
}

const initialState: State = {
  entity: null,
  status: 'idle',
}

export const fetchInterviewAction = createAsyncThunk(
  'interview/fetchInterview',
  async (interviewId: number | string, { rejectWithValue }) => {
    try {
      return await getInterview(interviewId).then(checkResponseDataStatus)
    } catch {
      return rejectWithValue({ message: '面談管理詳細の取得に失敗しました' })
    }
  },
)

export const createInterviewAction = createAsyncThunk(
  'interview/createInterview',
  async (params: InterviewParams, { rejectWithValue }) => {
    try {
      return await postInterview(params).then(checkResponseDataStatus)
    } catch {
      return rejectWithValue({ message: '面談管理詳細の作成に失敗しました' })
    }
  },
)

export const sendMailAction = createAsyncThunk(
  'interview/sendMail',
  async (params: InterviewSendMailParams, { rejectWithValue }) => {
    try {
      return await sendMailInterview(params).then(checkResponseDataStatus)
    } catch {
      return rejectWithValue({ message: 'メール送信に失敗しました' })
    }
  },
)

type InterviewActionArgs = {
  interviewId: number | string
  params: InterviewParams
}

export const updateInterviewAction = createAsyncThunk(
  'interview/updateInterview',
  async (args: InterviewActionArgs, { rejectWithValue }) => {
    try {
      return await patchInterview(args.interviewId, args.params).then(
        checkResponseDataStatus,
      )
    } catch {
      return rejectWithValue({ message: '面談管理詳細の更新に失敗しました' })
    }
  },
)

export const removeInterviewAction = createAsyncThunk(
  'interview/removeInterview',
  async (interviewId: number | string, { rejectWithValue }) => {
    try {
      return await deleteInterview(interviewId)
    } catch {
      return rejectWithValue({ message: '面談管理詳細の削除に失敗しました' })
    }
  },
)

const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    setInterview: (state, action: PayloadAction<Interview>) => {
      state.entity = action.payload
    },
    setStatusLoading: (state) => {
      state.status = 'loading'
    },
    setStatusSucceeded: (state) => {
      state.status = 'succeeded'
    },
    setStatusFailed: (state) => {
      state.status = 'failed'
    },
    resetInterview: (state) => {
      state.entity = initialState.entity
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(fetchInterviewAction.pending, createInterviewAction.pending),
        (state) => {
          interviewSlice.caseReducers.setStatusLoading(state)
        },
      )
      .addMatcher(
        isAnyOf(
          fetchInterviewAction.fulfilled,
          createInterviewAction.fulfilled,
        ),
        (state, action) => {
          interviewSlice.caseReducers.setStatusSucceeded(state)
          interviewSlice.caseReducers.setInterview(state, {
            payload: action.payload.interview,
            type: action.type,
          })
        },
      )
      .addMatcher(
        isAnyOf(fetchInterviewAction.rejected, createInterviewAction.rejected),
        (state) => {
          interviewSlice.caseReducers.setStatusFailed(state)
        },
      )
  },
})

export const selectInterview = (state: RootState): State['entity'] =>
  state.interview.interview.entity
export const selectInterviewStatus = (state: RootState): State['status'] =>
  state.interview.interview.status

export const interviewActions = interviewSlice.actions
export default interviewSlice.reducer
