import { URLParams } from '../../utils/strings'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'
import { api } from '@/repositories/base'
import { ApiResponse } from '../../interfaces/api'
import { checkResponseStatus } from '../../services/api'

export type UseFetchArguments = {
  url: string
  query?: URLParams | undefined
  config?: RequestInit
}

type UseFetchKeys = UseFetchArguments | null | false

export type UseFetchOption<D, E> = SWRConfiguration<D, E>

export type UseFetchReturn<D, E> = SWRResponse<D, E>

export const fetcher = <D extends ApiResponse>({
  url,
  query,
  config,
}: UseFetchArguments) =>
  api.get<D>(url, query, config).then(checkResponseStatus)

/**
 * データの取得
 *
 * キャッシュとして利用するデータを取得する。
 *
 * @param keys 取得キー
 * @param options オプション
 */
export const useFetch = <D extends ApiResponse, E = ApiResponse>(
  keys: UseFetchKeys,
  options?: UseFetchOption<D, E>,
): UseFetchReturn<D, E> => useSWR<D, E>(keys, fetcher, options)
