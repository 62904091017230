import {
  addMinutes,
  differenceInCalendarDays,
  differenceInMinutes,
  getYear,
  isValid,
  lightFormat,
  parse,
  set,
} from 'date-fns'
import { isString } from './strings'

export const date = {
  format: {
    /** yyyy-MM-dd */
    YYYY_MM_DD: 'yyyy-MM-dd',
    /** yyyy-M-d */
    YYYY_M_D: 'yyyy-M-d',
    /** yyyy/MM/dd */
    YYYY_MM_DD_SEPARATE_SLASH: 'yyyy/MM/dd',
    /** yyyy/MM/dd HH:mm */
    YYYY_MM_DD_HH_MM_RFC3339: "yyyy-MM-dd'T'HH:mm",
    /** yyyy年MM月dd日 */
    YYYY_MM_DD_JA: 'yyyy年MM月dd日',
    /** yyyy年MM月 */
    YYYY_MM_JA: 'yyyy年MM月',
    /** yyyyMMdd */
    YYYYMMDD: 'yyyyMMdd',
    /** HH:mm */
    HH_MM: 'HH:mm',
    /** yyyy年MM月dd日 HH時mm分 */
    YYYY_MM_DD_HH_MM_JA: 'yyyy年MM月dd日 HH時mm分',
    /** yyyyMMddHHmmss */
    YYYYMMDDHHMMSS: 'yyyyMMddHHmmss',
    /** yyyy-MM-dd HH:mm:ss */
    YYYY_MM_DD_HH_MM_SS: 'yyyy-MM-dd HH:mm:ss',
  },
}

const { YYYY_MM_DD, YYYY_MM_DD_JA, YYYY_MM_DD_SEPARATE_SLASH, YYYYMMDD } =
  date.format

export const format = (
  date: string | number,
  formatTo = YYYY_MM_DD_JA,
): string => {
  if (!date) {
    return ''
  }
  return lightFormat(new Date(date), formatTo)
}

/**
 * 日付文字列の変換
 *
 * 引数に指定した日付文字列を日付オブジェクト(Date)に変換する。
 *
 * @param date 変換対象日付文字列
 * @param format 変換対象日付書式
 * @param reference 参照日付オブジェクト(変換に失敗した場合)
 */
export const parseDate = (
  date: string,
  format: string,
  reference = new Date(),
) => parse(date, format, reference)

/**
 * 日付有効性の判定
 *
 * 指定した文字列が日付として有効であるかを判定する。
 * デフォルトでは `yyyy-MM-dd`, `yyyy/MM/dd`, `yyyyMMdd` を判定対象とする。
 * それ以外を有効にする場合には、判定書式配列に指定する。
 *
 * @param value 日付判定文字列
 * @param formats 判定書式配列
 * @param referenceDate 参照日付オブジェクト
 */
export const isValidDate = (
  value: string | null | undefined,
  formats = [YYYY_MM_DD, YYYY_MM_DD_SEPARATE_SLASH, YYYYMMDD],
  referenceDate = new Date(),
): boolean => {
  if (value === null || value === undefined) {
    return false
  }

  for (const format of formats) {
    if (format.length > value?.length) {
      continue
    }
    if (isValid(parse(value, format, referenceDate))) {
      return true
    }
  }
  return false
}

export const isSameOrAfterDate = (fromDate: string, toDate: string) => {
  const diff = differenceInCalendarDays(new Date(toDate), new Date(fromDate))
  return diff >= 0
}

export const isSameOrAfterMinutes = (fromDate: string, toDate: string) => {
  const diff = differenceInMinutes(new Date(toDate), new Date(fromDate))
  return diff >= 0
}

export const isSameOrAfterToday = (value: string): boolean => {
  const today = lightFormat(Date.now(), date.format.YYYY_MM_DD)
  return isSameOrAfterDate(today, value)
}

export const startDayOfFiscalYear = (year: number): string => {
  return `${year}-04-01`
}

export const endDayOfFiscalYear = (year: number): string => {
  return `${year + 1}-03-31`
}

/**
 * 時間の追加
 * @param fromDate 基準となる日付
 * @param amount 追加分(デフォルトは30分)
 */
export const addDateMinutes = (
  fromDate: string | number | Date,
  amount = 30,
) => {
  const date = isString(fromDate) ? new Date(fromDate) : fromDate
  return addMinutes(date, amount)
}

export const getAge = (
  date: string,
  target = Date.now(),
): number | undefined => {
  if (!date) {
    return undefined
  }

  const today = new Date(target)
  const birthday = new Date(date)
  const thisYearBirthday = set(birthday, { year: today.getFullYear() })

  const age = today.getFullYear() - birthday.getFullYear()

  // 今年の誕生日を迎えていなければ 年齢 - 1
  return today < thisYearBirthday ? age - 1 : age
}

/**
 * 提案年の取得
 *
 * 以下の定義に基づいて年リスト(years)から特定の年を返却する。
 * (1) 今年があれば返却する
 * (2) 今年がない場合、リストの中で最も現在に近い未来の年を返却する
 * (3) 今年とそれ以降がない場合、リストの中で最も現在に近い過去の年を返却する
 * (4) 上記全てが見つけられない場合には年リストの最初の値を返却する
 * @param years 年リスト
 */
export const getProposalYear = (years: number[]): number => {
  const thisYear = getYear(Date.now())

  if (years.includes(thisYear)) {
    // (1)
    return thisYear
  } else {
    // (2)
    const future = years.filter((year) => year > thisYear).sort()[0]

    if (future) {
      return future
    } else {
      // (3)
      const past = years
        .filter((year) => year < thisYear)
        .sort()
        .reverse()[0]
      // (4)
      return past ?? years[0]
    }
  }
}

/**
 * 年の取得
 *
 * 引数に指定した日付から年を取得する。
 *
 * @param date 日付
 * @returns 年数値
 */
export const getDateYear = (date: number | Date) => getYear(date)
