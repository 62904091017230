import { StrictMode, Suspense } from 'react'
import { store } from '@/stores'
import { Provider } from 'react-redux'
import { NotifyProvider } from './NotifyProvider'
import CssBaseline from '@mui/material/CssBaseline'
import { Snackbar } from '@/pages/app/Snackbar'
import { ErrorBoundary } from '@sentry/react'
import { InternalError } from '@/pages/app/InternalError'
import OverlayLoading from '@/components/Loading/OverlayLoading'
import { CacheProvider } from './CacheProvider'
import { RouteProvider } from './RouteProvider'
import { StyleProvider } from './StyleProvider'

export const AppProvider = () => (
  <StrictMode>
    <Provider store={store}>
      <StyleProvider>
        <ErrorBoundary fallback={(error) => <InternalError {...error} />}>
          <NotifyProvider>
            <CacheProvider>
              <RouteProvider />
              <Suspense fallback={<OverlayLoading open hiddenProgress />}>
                <CssBaseline />
                <Snackbar />
              </Suspense>
            </CacheProvider>
          </NotifyProvider>
        </ErrorBoundary>
      </StyleProvider>
    </Provider>
  </StrictMode>
)
