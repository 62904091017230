import { StoreStatus } from '../../../interfaces/api'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { InterviewReason } from '../../../interfaces/shareNavi/interview'
import { RootState } from '../../index'
import {
  getInterviewReasons,
  InterviewReasonsData,
} from '@/repositories/shareNavi/interview'
import { checkResponseDataStatus } from '../../../services/api'

interface State {
  status: StoreStatus
}

const initial: State = {
  status: 'idle',
}

const adapter = createEntityAdapter<InterviewReason>()
const initialState = adapter.getInitialState(initial)

export const fetchInterviewReasonsAction = createAsyncThunk<
  InterviewReasonsData,
  void,
  { state: RootState }
>(
  'interviewReasons/fetchInterviewReasons',
  async (_, { rejectWithValue }) => {
    try {
      return await getInterviewReasons().then(checkResponseDataStatus)
    } catch (error) {
      return error instanceof Error
        ? rejectWithValue({ message: error.message })
        : rejectWithValue({ message: '面談理由一覧の取得に失敗しました' })
    }
  },
  {
    condition: (_, { getState }) =>
      selectInterviewReasonsTotal(getState()) === 0,
  },
)

const interviewReasonsSlice = createSlice({
  name: 'interviewReasons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInterviewReasonsAction.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchInterviewReasonsAction.fulfilled, (state, action) => {
        state.status = 'succeeded'
        adapter.setAll(state, action.payload.reasons)
      })
      .addCase(fetchInterviewReasonsAction.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const {
  selectAll: selectInterviewReasons,
  selectTotal: selectInterviewReasonsTotal,
} = adapter.getSelectors((state: RootState) => state.interview.interviewReasons)

export const selectInterviewReasonsStatus = (
  state: RootState,
): State['status'] => state.interview.interviewReasons.status

export default interviewReasonsSlice.reducer
