import { Branch, Department } from '../interfaces/setting'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  BranchesData,
  DepartmentsData,
  EmployeeGendersData,
  EmployeeStatusesData,
  EmployeeTitlesData,
  UsersData,
} from '@/repositories/setting'
import {
  EmployeeGender,
  EmployeeStatus,
  EmployeeTitle,
} from '../interfaces/employee'
import { UserSummary } from '../interfaces/user'
import { ImportErrorResponse, Pagination } from '../interfaces/api'

interface State {
  users: UserSummary[] | null
  branches: Branch[] | null
  branch: Branch | null
  departments: Department[] | null
  department: Department | null
  employeeTitles: EmployeeTitle[] | null
  employeeStatuses: EmployeeStatus[] | null
  employeeGenders: EmployeeGender[] | null
  waitingUsers: boolean
  waitingBranches: boolean
  waitingDepartments: boolean
  waitingEmployeeTitles: boolean
  waitingEmployeeStatuses: boolean
  waitingEmployeeGenders: boolean
  waitingUploadCsv: boolean
  waitingDownloadCsv: boolean
  errorResponseResults: ImportErrorResponse[] | null
  paginationBranches: Pagination | null
  paginationDepartments: Pagination | null
}

const initialState: State = {
  users: null,
  branches: null,
  branch: null,
  departments: null,
  department: null,
  employeeTitles: null,
  employeeStatuses: null,
  employeeGenders: null,
  waitingUsers: false,
  waitingBranches: false,
  waitingDepartments: false,
  waitingEmployeeTitles: false,
  waitingEmployeeStatuses: false,
  waitingEmployeeGenders: false,
  waitingUploadCsv: false,
  waitingDownloadCsv: false,
  errorResponseResults: null,
  paginationBranches: null,
  paginationDepartments: null,
}

const setting = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setUsersWaiting: (state) => {
      state.waitingUsers = true
    },
    setUsers: (state, action: PayloadAction<UsersData>) => {
      state.users = action.payload.users
      state.waitingUsers = false
    },
    setBranchWaiting: (state): void => {
      state.waitingBranches = true
    },
    setBranches: (state, actions: PayloadAction<BranchesData>): void => {
      state.branches = actions.payload.branches
      state.waitingBranches = false
    },
    setDepartmentWaiting: (state): void => {
      state.waitingDepartments = true
    },
    setDepartments: (state, actions: PayloadAction<DepartmentsData>): void => {
      state.departments = actions.payload.departments
      state.waitingDepartments = false
    },
    setEmployeeTitlesWaiting: (state) => {
      state.waitingEmployeeTitles = true
    },
    setEmployeeTitles: (state, actions: PayloadAction<EmployeeTitlesData>) => {
      state.employeeTitles = actions.payload.employeeTitles
      state.waitingEmployeeTitles = initialState.waitingEmployeeTitles
    },
    setEmployeeStatusWaiting: (state): void => {
      state.waitingEmployeeStatuses = true
    },
    setEmployeeStatus: (
      state,
      actions: PayloadAction<EmployeeStatusesData>,
    ): void => {
      state.employeeStatuses = actions.payload.employeeStatuses
      state.waitingEmployeeStatuses = false
    },
    setWaitingEmployeeGenders: (state) => {
      state.waitingEmployeeGenders = true
    },
    setEmployeeGenders: (state, action: PayloadAction<EmployeeGendersData>) => {
      state.employeeGenders = action.payload.employeeGenders
    },
    resetUsers: (state) => {
      state.users = initialState.users
    },
    resetBranches: (state) => {
      state.branches = initialState.branches
      state.paginationBranches = initialState.paginationBranches
    },
    resetDepartments: (state) => {
      state.departments = initialState.departments
    },
    resetEmployeeTitles: (state) => {
      state.employeeTitles = initialState.employeeTitles
    },
    resetEmployeeStatuses: (state) => {
      state.employeeStatuses = initialState.employeeStatuses
    },
    resetEmployeeGenders: (state) => {
      state.employeeGenders = initialState.employeeGenders
    },
    resetWaitingEmployeeGenders: (state) => {
      state.waitingEmployeeGenders = initialState.waitingEmployeeGenders
    },
    setPaginationBranches: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ): void => {
      state.paginationBranches = actions.payload.pagination
    },
    resetBranch: (state) => {
      state.branch = initialState.branch
    },
    setWaitingUploadCsv: (state): void => {
      state.waitingUploadCsv = true
    },
    setErrorResponseResults: (
      state,
      actions: PayloadAction<{ errors: ImportErrorResponse[] }>,
    ): void => {
      state.errorResponseResults = actions.payload.errors
    },
    resetErrorResponseResults: (state): void => {
      state.errorResponseResults = initialState.errorResponseResults
    },
    resetWaitingUploadCsv: (state): void => {
      state.waitingUploadCsv = initialState.waitingUploadCsv
    },
    setWaitingDownloadCsv: (state): void => {
      state.waitingDownloadCsv = true
    },
    resetWaitingDownloadCsv: (state): void => {
      state.waitingDownloadCsv = initialState.waitingDownloadCsv
    },
    setPaginationDepartments: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ): void => {
      state.paginationDepartments = actions.payload.pagination
    },
  },
})

export const actions = setting.actions
export default setting.reducer
