import { useDispatch, useSelector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth'
import snackbarReducer from './snackbar'
import dashboardReducer from './dashboard'
import wellnessLiteReducer from './wellnessLite'
import karteReducer from './karte'
import { checkupReducer } from './healthCare/checkup'
import employeeReducer from './employee'
import branchReducer from './branch'
import departmenthReducer from './department'
import settingReducer from './setting'
import naviReducer from './navi'
import personnelReportReducer from './shareNavi/personnelReport'
import stressCheckReducer from './healthCare/stressCheck'
import overworkReducer from './healthCare/overwork'
import interviewReducer from './shareNavi/interview'
import noticeReducer from './notice'
import documentReducer from './document'
import userReducer from './user'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    snackbar: snackbarReducer,
    dashboard: dashboardReducer,
    wellnessLite: wellnessLiteReducer,
    navi: naviReducer,
    karte: karteReducer,
    checkup: checkupReducer,
    employee: employeeReducer,
    branch: branchReducer,
    department: departmenthReducer,
    setting: settingReducer,
    personnelReport: personnelReportReducer,
    stressCheck: stressCheckReducer,
    overwork: overworkReducer,
    interview: interviewReducer,
    notice: noticeReducer,
    document: documentReducer,
    user: userReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
