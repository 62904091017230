import {
  CheckupResultEmployeeAllResponse,
  CheckupResultEmployeesParams,
} from '@/repositories/healthCare/checkup'
import { useFetch } from '@/hooks/State'
import { api } from '@/repositories/base'

export type UseFetchCheckUpResultEmployeeAllProps = {
  fiscalYearId: number | string | undefined
  params?: CheckupResultEmployeesParams | undefined
}

/*
 * 一次判定全対象者一覧の取得
 *
 * 一次判定の全対象者を取得する。
 * ただし、レスポンスは処理に必要なIDのみに限定する。
 */
export const useFetchCheckUpResultEmployeeAll = ({
  fiscalYearId,
  params,
}: UseFetchCheckUpResultEmployeeAllProps) => {
  const { data, isLoading, isValidating, mutate } =
    useFetch<CheckupResultEmployeeAllResponse>(getKey({ fiscalYearId, params }))

  return {
    checkupSurveysAll: data?.data.checkupSurveys,
    isLoading,
    isValidating,
    mutate,
  }
}

/**
 * キーの取得
 *
 * もし抽出に必要な fiscalYearId が未定義の場合は false を返し、
 * データを取得しないようにする。
 */
const getKey = (
  { fiscalYearId, params: query }: UseFetchCheckUpResultEmployeeAllProps,
  apiVersion = api.V1,
) =>
  fiscalYearId === undefined
    ? false
    : {
        url: `${apiVersion}/checkup/results/${fiscalYearId}/judgment_all_ids`,
        query,
      }
