import { User } from '../interfaces/user'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UsersData } from '@/repositories/setting/userSetting'
import { Pagination } from '../interfaces/api'

interface State {
  users: User[] | null
  user: User | null
  paginationUsers: Pagination | null
  waitingUsers: boolean
  waitingUser: boolean
  sendMailSelectedOption: number
  sendMailIsRetry: boolean
}

const initialState: State = {
  users: null,
  user: null,
  paginationUsers: null,
  waitingUsers: false,
  waitingUser: false,
  sendMailSelectedOption: 0,
  sendMailIsRetry: false,
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setWaitingUsers: (state): void => {
      state.waitingUsers = true
    },
    setWaitingUser: (state): void => {
      state.waitingUser = true
    },
    setUsers: (state, actions: PayloadAction<UsersData>): void => {
      state.users = actions.payload.users
      state.waitingUsers = initialState.waitingUsers
    },
    setUser: (state, actions: PayloadAction<User>): void => {
      state.user = actions.payload
      state.waitingUser = false
    },
    setPaginationUsers: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ): void => {
      state.paginationUsers = actions.payload.pagination
    },
    setSendMailSelectedOption: (state, action: PayloadAction<number>) => {
      state.sendMailSelectedOption = action.payload
    },
    setSendMailIsRetry: (state, action: PayloadAction<boolean>) => {
      state.sendMailIsRetry = action.payload
    },
    resetUsers: (state) => {
      state.users = initialState.users
      state.paginationUsers = initialState.paginationUsers
    },
    resetUser: (state) => {
      state.user = initialState.user
    },
    resetSendMailSelectedOption: (state) => {
      state.sendMailSelectedOption = initialState.sendMailSelectedOption
    },
  },
})

export const actions = user.actions
export default user.reducer
