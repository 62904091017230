import { useEffect, useRef, useState } from 'react'
import * as React from 'react'
import { useSnackbar } from 'notistack'
import {
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Lock } from '@mui/icons-material'
import { setUserAccount } from '../../utils/userStorage'
import { ApiOtpCreate, ApiOtpNew } from '@/repositories/otp'
import { OtpMail } from './OtpMail'
import { OtpApp } from './OtpApp'
import { CancelButton } from '../../components/Button/CancelButton'
import { CloseButtonDialog } from '../../components/Dialog/CloseButtonDialog'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    button: {
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
    },
    icon: {
      fontSize: 20,
      marginLeft: theme.spacing(1),
      opacity: 0.8,
    },
  }),
)

type Props = {
  isOpen: boolean
  isSelect: boolean
  userId: number
  email: string
  otpAuth: number
  otpAuthName: string
  lastOtpDate: string
  otpSecret: string
  onSuccess: () => void
  onCancel: () => void
}

export const OtpDialog: React.FC<Props> = (props: Props) => {
  const { isOpen, userId, email, onSuccess, onCancel } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [otpData, setOtpData] = useState({
    isSelect: props.isSelect,
    otpAuth: props.otpAuth,
    otpAuthName: props.otpAuthName,
    lastOtpDate: props.lastOtpDate,
    otpSecret: props.otpSecret,
    qrCode: '',
  })
  const [validOtp, setValidOtp] = useState({
    value: '',
    error: false,
    message: '',
    inputRef: useRef<HTMLInputElement>(null),
  })

  useEffect(() => {
    if (isOpen) {
      setValidOtp({
        ...validOtp,
        error: false,
        message: '',
        value: '',
      })
      setOtpData({
        ...otpData,
        isSelect: props.isSelect,
        otpAuth: props.otpAuth ?? 0,
        otpAuthName: props.otpAuthName ?? '',
        lastOtpDate: props.lastOtpDate ?? '',
        otpSecret: props.otpSecret ?? '',
      })
      if (props.otpAuth == 1 && props.lastOtpDate) {
        postOtpNew(1)
      }
    }
  }, [isOpen])

  const handleOtpNoneSubmit = () => {
    return postOtpNew(0)
  }

  const handleOtpMailSubmit = () => {
    return postOtpNew(1)
  }

  const handleOtpAppSubmit = () => {
    return postOtpNew(2)
  }

  const handleOtpCreateSubmit = () => {
    return postOtpCreate()
  }

  const postOtpNew = (otpAuth: number) => {
    ApiOtpNew({
      userId: userId,
      email: email,
      otpAuth: otpAuth,
    }).then((response) => {
      try {
        if (response.status === 'success') {
          setUserAccount(response.data.otp)
          if (response.data.otp.otpAuth == 0) {
            return onSuccess()
          } else {
            return setOtpData({
              ...otpData,
              isSelect: false,
              otpAuth: response.data.otp.otpAuth,
              otpAuthName: response.data.otp.otpAuthName,
              lastOtpDate: response.data.otp.lastOtpDate,
              otpSecret: response.data.otp.otpSecret,
              qrCode: response.data.otp.qrCode,
            })
          }
        }
        if (response.status === 'error') {
          return enqueueSnackbar(response.message, {
            variant: 'error',
            key: 'otp',
          })
        }
      } catch (e) {
        return enqueueSnackbar('2段階認証に失敗しました', {
          variant: 'error',
          key: 'otp',
        })
      }
    })
  }

  const postOtpCreate = () => {
    ApiOtpCreate({
      userId: userId,
      email: email,
      otpAuth: otpData.otpAuth,
      otpSecret: otpData.otpSecret,
      otpAttempt: validOtp.value,
    }).then((response) => {
      try {
        if (response.status === 'success') {
          setUserAccount(response.data.otp)
          return onSuccess()
        }
        if (response.status === 'error') {
          return enqueueSnackbar(response.message, {
            variant: 'error',
            key: 'otp',
          })
        }
      } catch (e) {
        return enqueueSnackbar('2段階認証に失敗しました', {
          variant: 'error',
          key: 'otp',
        })
      }
    })
  }

  const handleChangeOtp = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validOtp.inputRef.current === null) {
      return
    }
    if (!validOtp.inputRef.current.validity.valid) {
      setValidOtp({
        ...validOtp,
        error: true,
        message: '6桁の認証コードを入力して下さい',
        value: event.currentTarget.value,
      })
    } else {
      setValidOtp({
        ...validOtp,
        error: false,
        message: '',
        value: event.currentTarget.value,
      })
    }
  }

  return (
    <CloseButtonDialog open={isOpen} title="２段階認証設定" onClose={onCancel}>
      <DialogContent>
        {otpData.isSelect ? (
          <div className={classes.paper}>
            <Typography variant="body2" color="textSecondary" align="center">
              ログインする際に２段階認証でアカウントを保護できます。
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              本人確認をする際の認証コードを受け取る方法を選択して下さい。
            </Typography>

            <Button
              variant="contained"
              fullWidth
              color="primary"
              style={{ width: 300 }}
              onClick={handleOtpMailSubmit}
              className={classes.button}
            >
              メールで受け取る
            </Button>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              style={{ width: 300 }}
              onClick={handleOtpAppSubmit}
              className={classes.button}
            >
              認証アプリを利用する
            </Button>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              style={{ width: 300 }}
              onClick={handleOtpNoneSubmit}
              className={classes.button}
            >
              設定しない
            </Button>
          </div>
        ) : (
          <div className={classes.paper}>
            <Typography component="h6" variant="h6">
              {otpData.otpAuthName}
            </Typography>
            <br />
            {otpData.qrCode && <OtpApp qrCode={otpData.qrCode} />}
            {otpData.otpAuth == 1 && <OtpMail />}

            <Typography variant="body2" component="p" color="textSecondary">
              ６桁の認証コードを入力してください。
            </Typography>
            <TextField
              id="otpAttempt"
              required
              variant="outlined"
              label="認証コード"
              type="text"
              value={validOtp.value}
              onChange={handleChangeOtp}
              margin="normal"
              style={{ width: 200 }}
              name="otpAttempt"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock className={classes.icon} />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                minLength: 6,
                maxLength: 6,
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              inputRef={validOtp.inputRef}
              error={validOtp.error}
              helperText={validOtp.message}
            />
            <Button
              variant="contained"
              style={{ width: 200 }}
              color="primary"
              size="medium"
              onClick={handleOtpCreateSubmit}
              className={classes.button}
            >
              認証する
            </Button>
            <br />
            <a href="mailto:system-c@elixia.co.jp">サポートに連絡</a>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onCancel} />
      </DialogActions>
    </CloseButtonDialog>
  )
}
