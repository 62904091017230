import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { Interview } from '../../../interfaces/shareNavi/interview'
import { RootState } from '../../index'
import { Pagination, StoreStatus } from '../../../interfaces/api'
import {
  getInterviews,
  InterviewsQuery,
} from '@/repositories/shareNavi/interview'
import { checkResponseStatus } from '../../../services/api'

interface State {
  status: StoreStatus
  pagination: Pagination | null
}

const initial: State = {
  status: 'idle',
  pagination: null,
}

const adapter = createEntityAdapter<Interview>()
const initialState = adapter.getInitialState(initial)

export const fetchInterviewsAction = createAsyncThunk(
  'interviews/fetchInterviews',
  async (query: InterviewsQuery | undefined, { rejectWithValue }) => {
    try {
      const response = await getInterviews(query).then(checkResponseStatus)
      return {
        interviews: response.data.interviews,
        pagination: response.meta ?? null,
      }
    } catch (error) {
      return error instanceof Error
        ? rejectWithValue({ message: error.message })
        : rejectWithValue({ message: '面談管理一覧の取得に失敗しました' })
    }
  },
)

const interviewsSlice = createSlice({
  name: 'interviews',
  initialState,
  reducers: {
    resetInterviews: adapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInterviewsAction.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchInterviewsAction.fulfilled, (state, action) => {
        state.status = 'succeeded'
        adapter.setAll(state, action.payload.interviews)
        state.pagination = action.payload.pagination
      })
      .addCase(fetchInterviewsAction.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const { selectAll: selectInterviews } = adapter.getSelectors(
  (state: RootState) => state.interview.interviews,
)
export const selectInterviewsStatus = (state: RootState): State['status'] =>
  state.interview.interviews.status
export const selectInterviewsPagination = (
  state: RootState,
): State['pagination'] => state.interview.interviews.pagination

export default interviewsSlice.reducer
