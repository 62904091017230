import {
  StressCheck,
  StressCheckGroupSummary,
  StressCheckSurvey,
} from '../../interfaces/healthCare/stressCheck'
import { Employee } from '../../interfaces/employee'
import { Pagination } from '../../interfaces/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  StressCheckData,
  StressCheckGroupSummaryData,
  StressChecksData,
  StressCheckSurveysData,
  StressCheckTargetEmployeesData,
} from '@/repositories/healthCare/stressCheck'

interface State {
  stressChecks: StressCheck[] | null
  stressCheck: StressCheck | null
  stressCheckSurveys: StressCheckSurvey[] | null
  stressCheckTargetEmployees: Employee[] | null
  stressCheckGroupSummary: StressCheckGroupSummary | null

  paginationStressChecks: Pagination | null
  paginationStressCheckSurveys: Pagination | null
  paginationStressCheckTargetEmployees: Pagination | null

  errorMessage: string | null

  waitingStressChecks: boolean
  waitingStressCheck: boolean
  waitingStressCheckSurveys: boolean
  waitingStressCheckTargetEmployees: boolean
  waitingStressCheckSendMail: boolean
  waitingStressCheckGroupSummary: boolean
}

const initialState: State = {
  stressChecks: null,
  stressCheck: null,
  stressCheckSurveys: null,
  stressCheckTargetEmployees: null,
  stressCheckGroupSummary: null,

  paginationStressChecks: null,
  paginationStressCheckSurveys: null,
  paginationStressCheckTargetEmployees: null,

  errorMessage: null,

  waitingStressChecks: false,
  waitingStressCheck: false,
  waitingStressCheckSurveys: false,
  waitingStressCheckTargetEmployees: false,
  waitingStressCheckSendMail: false,
  waitingStressCheckGroupSummary: false,
}

const stressCheck = createSlice({
  name: 'stressCheck',
  initialState,
  reducers: {
    setWaitingStressChecks: (state) => {
      state.waitingStressChecks = true
    },
    setWaitingStressCheck: (state) => {
      state.waitingStressCheck = true
    },
    setWaitingStressCheckSurveys: (state) => {
      state.waitingStressCheckSurveys = true
    },
    setWaitingStressCheckTargetEmployees: (state) => {
      state.waitingStressCheckTargetEmployees = true
    },
    setWaitingStressCheckSendMail: (state) => {
      state.waitingStressCheckSendMail = true
    },
    setWaitingStressCheckGroupSummary: (state) => {
      state.waitingStressCheckGroupSummary = true
    },
    setStressChecks: (state, action: PayloadAction<StressChecksData>) => {
      state.stressChecks = action.payload.stressChecks
    },
    setStressCheck: (state, action: PayloadAction<StressCheckData>) => {
      state.stressCheck = action.payload.stressCheck
    },
    setStressCheckSurveys: (
      state,
      action: PayloadAction<StressCheckSurveysData>,
    ) => {
      state.stressCheckSurveys = action.payload.stressCheckSurveys
    },
    setStressCheckTargetEmployees: (
      state,
      action: PayloadAction<StressCheckTargetEmployeesData>,
    ) => {
      state.stressCheckTargetEmployees = action.payload.employees
    },
    setStressCheckGroupSummary: (
      state,
      action: PayloadAction<StressCheckGroupSummaryData>,
    ) => {
      state.stressCheckGroupSummary = action.payload.stressCheckGroupResult
      state.errorMessage = initialState.errorMessage
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
    setPaginationStressChecks: (
      state,
      actions: PayloadAction<Pagination | null>,
    ) => {
      state.paginationStressChecks = actions.payload
    },
    setPaginationStressCheckSurveys: (
      state,
      actions: PayloadAction<Pagination | null>,
    ) => {
      state.paginationStressCheckSurveys = actions.payload
    },
    setPaginationStressCheckTargetEmployees: (
      state,
      actions: PayloadAction<Pagination | null>,
    ) => {
      state.paginationStressCheckTargetEmployees = actions.payload
    },
    resetWaitingStressChecks: (state) => {
      state.waitingStressChecks = initialState.waitingStressChecks
    },
    resetWaitingStressCheck: (state) => {
      state.waitingStressCheck = initialState.waitingStressCheck
    },
    resetWaitingStressCheckSurveys: (state) => {
      state.waitingStressCheckSurveys = initialState.waitingStressCheckSurveys
    },
    resetWaitingStressCheckTargetEmployees: (state) => {
      state.waitingStressCheckTargetEmployees =
        initialState.waitingStressCheckTargetEmployees
    },
    resetWaitingStressCheckSendMail: (state) => {
      state.waitingStressCheckSendMail = initialState.waitingStressCheckSendMail
    },
    resetWaitingStressCheckGroupSummary: (state) => {
      state.waitingStressCheckGroupSummary =
        initialState.waitingStressCheckGroupSummary
    },
    resetStressChecks: (state) => {
      state.stressChecks = initialState.stressChecks
    },
    resetStressCheck: (state) => {
      state.stressCheck = initialState.stressCheck
    },
    resetStressCheckSurveys: (state) => {
      state.stressCheckSurveys = initialState.stressCheckSurveys
    },
    resetStressCheckTargetEmployees: (state) => {
      state.stressCheckTargetEmployees = initialState.stressCheckTargetEmployees
    },
    resetStressCheckGroupSummary: (state) => {
      state.stressCheckGroupSummary = initialState.stressCheckGroupSummary
    },
  },
})

export const actions = stressCheck.actions
export default stressCheck.reducer
