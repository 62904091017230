import { AppDispatch, store } from '../../../stores'
import {
  FiscalYearParams,
  FiscalYearsData,
  getFiscalYears,
  postFiscalYear,
} from '@/repositories/healthCare/checkup'
import { actions } from '@/stores/healthCare/checkup'
import { enqueueErrorSnackbar, enqueueSnackbar } from '../../snackbars'
import { FiscalYear } from '../../../interfaces/healthCare/checkup'
import { eachYearOfInterval } from '../../../utils/arrays'
import { getDateYear, getProposalYear } from '../../../utils/dates'
import { checkResponseDataStatus } from '../../api'

/**
 * 年度一覧の取得
 *
 * 年度一覧を取得して store に格納する。
 * ただしすでに年度が読み込まれている場合には取得しない。
 */
export const fetchFiscalYears =
  (options?: {
    /** 強制読込 */
    forceFetch?: boolean
    /** 選択年度ID */
    selectedId?: number
  }) =>
  (
    dispatch: AppDispatch,
  ): Promise<void | { payload: FiscalYearsData; type: string }> => {
    const { fiscalYears, selectedYear } = store.getState().checkup
    if (!options?.forceFetch && fiscalYears !== null) {
      return Promise.resolve()
    }

    dispatch(actions.setWaitingFiscalYears())
    return getFiscalYears()
      .then(checkResponseDataStatus)
      .then((data) => dispatch(actions.setFiscalYears(data)))
      .then((data) => {
        const selectYearId =
          (options?.selectedId ?? 0) > 0
            ? options?.selectedId
            : selectedYear?.id
        const defaultYearId = getDefaultYearIdIfAbsent(
          data.payload.fiscalYears,
          selectYearId,
        )
        defaultYearId && dispatch(selectYear(defaultYearId))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
        dispatch(actions.resetWaitingFiscalYears())
      })
  }

export const selectYear =
  (fiscalYearId: number) =>
  (dispatch: AppDispatch): void => {
    const fiscalYear = getFiscalYearFromId(fiscalYearId)
    fiscalYear && dispatch(actions.setSelectedYear({ fiscalYear }))
  }

export const changeFiscalYear =
  (key: keyof FiscalYearParams, value: number) =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.changeFiscalYear({ key, value }))
  }

export const createFiscalYear =
  (params: FiscalYearParams | null, callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (params === null) {
      return Promise.resolve()
    }
    dispatch(actions.setWaitingFiscalYears())
    return postFiscalYear(params)
      .then(checkResponseDataStatus)
      .then((data) => dispatch(actions.setFiscalYear(data)))
      .then(() => dispatch(enqueueSnackbar('健診年度の登録が完了しました')))
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const resetFiscalYear =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.resetFiscalYear())
  }

/**
 * 年度の初期値とオプション
 *
 * 年度作成ダイアログで表示する初期値と作成選択候補のデータを作成する。
 * 選択値は 今年 にする。
 * すでに作成済みの年度については選択メニューを非活性(選択不可)
 * @param years
 */
export const useFiscalYearOptions = (years: {
  fiscalYears: FiscalYear[] | null
}): {
  value: number
  options: { value: number; label: string; disabled: boolean }[]
} => {
  const options = getAvailableYears().map((year) => {
    const disabled = !!years.fiscalYears?.find(
      (fiscalYear) => fiscalYear.fiscalYear === year,
    )
    return {
      value: year,
      label: `${year}年`,
      disabled,
    }
  })

  const thisYear = getDateYear(Date.now())

  return {
    value: thisYear,
    options,
  }
}

const getAvailableYears = (): number[] => {
  return eachYearOfInterval(Date.now(), 1, 10)
}

const getFiscalYearFromId = (fiscalYearId: number): FiscalYear | undefined => {
  const { fiscalYears } = store.getState().checkup
  return fiscalYears?.find((years) => years.id === fiscalYearId)
}

/**
 * 初期年度IDの取得
 *
 * もし、選択している年度(selectedYearId)が空であるか、一覧に存在しない年度が選択されていたら
 * 一覧の中で推奨する年度IDを返す。
 *
 * @see getProposalYear
 * @param fiscalYears 年度オブジェクト一覧
 * @param selectedYearId 選択した年度ID
 */
const getDefaultYearIdIfAbsent = (
  fiscalYears: FiscalYear[],
  selectedYearId: number | undefined,
): number | null => {
  if (fiscalYears?.length === 0) {
    return null
  }

  if (selectedYearId === undefined) {
    const year = getProposalYear(
      fiscalYears.map((fiscalYear) => fiscalYear.fiscalYear),
    )
    return (
      fiscalYears.find((fiscalYear) => fiscalYear.fiscalYear === year)?.id ??
      null
    )
  } else {
    const exist = fiscalYears
      .map((fiscalYear) => fiscalYear.id)
      .includes(selectedYearId)
    return exist ? selectedYearId : fiscalYears[0].id
  }
}
