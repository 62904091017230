import { Employee } from '../interfaces/employee'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EmployeesData } from '@/repositories/setting'
import { EmployeePagination, ImportErrorResponse } from '../interfaces/api'

interface State {
  employees: Employee[] | null
  employee: Employee | null
  paginationEmployees: EmployeePagination | null
  waitingEmployees: boolean
  waitingEmployee: boolean
  waitingUploadCsv: boolean
  waitingDownloadCsv: boolean
  errorResponseResults: ImportErrorResponse[] | null
}

const initialState: State = {
  employees: null,
  employee: null,
  paginationEmployees: null,
  waitingEmployees: false,
  waitingEmployee: false,
  waitingUploadCsv: false,
  waitingDownloadCsv: false,
  errorResponseResults: null,
}

const employee = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setWaitingEmployees: (state): void => {
      state.waitingEmployees = true
    },
    setWaitingEmployee: (state): void => {
      state.waitingEmployee = true
    },
    setEmployees: (state, actions: PayloadAction<EmployeesData>): void => {
      state.employees = actions.payload.employees
      state.waitingEmployees = initialState.waitingEmployees
    },
    setEmployee: (state, actions: PayloadAction<Employee>): void => {
      state.employee = actions.payload
      state.waitingEmployee = false
    },
    setPaginationEmployees: (
      state,
      actions: PayloadAction<{ pagination: EmployeePagination | null }>,
    ): void => {
      state.paginationEmployees = actions.payload.pagination
    },
    resetEmployees: (state) => {
      state.employees = initialState.employees
      state.paginationEmployees = initialState.paginationEmployees
    },
    resetEmployee: (state) => {
      state.employee = initialState.employee
    },
    setWaitingUploadCsv: (state): void => {
      state.waitingUploadCsv = true
    },
    setWaitingDownloadCsv: (state): void => {
      state.waitingDownloadCsv = true
    },
    resetWaitingUploadCsv: (state): void => {
      state.waitingUploadCsv = initialState.waitingUploadCsv
    },
    resetWaitingDownloadCsv: (state): void => {
      state.waitingDownloadCsv = initialState.waitingDownloadCsv
    },
    setErrorResponseResults: (
      state,
      actions: PayloadAction<{ errors: ImportErrorResponse[] }>,
    ): void => {
      state.errorResponseResults = actions.payload.errors
    },
    resetErrorResponseResults: (state): void => {
      state.errorResponseResults = initialState.errorResponseResults
    },
  },
})

export const actions = employee.actions
export default employee.reducer
