import {
  CheckupEvent,
  CheckupEventCandidate,
  CheckupEventEmployee,
  CheckupFinalResultEmployee,
  CheckupFinalResultEmployeeDetail,
  CheckupResult,
  CheckupResultDocument,
  CheckupResultEmployee,
  Clinic,
  FiscalYear,
} from '../../interfaces/healthCare/checkup'
import { ValidateError } from '../../interfaces/validation'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Prop } from '../../interfaces/entity'
import { ImportErrorResponse, Pagination } from '../../interfaces/api'
import {
  CheckupFinalResultEmployeeData,
  CheckupFinalResultEmployeeParams,
  CheckupFinalResultEmployeesData,
  CheckupResultClinicsData,
  CheckupResultData,
  CheckupResultDocumentData,
  CheckupResultEmployeeData,
  CheckupResultsData,
  EventParams,
  FiscalYearParams,
} from '@/repositories/healthCare/checkup'
import { ClinicParams } from '@/repositories/healthCare/setting/clinic'
import { initialResult } from '../../pages/healthCare/checkup/hooks/useCheckupResultHistory'

interface State {
  clinics: Clinic[] | null
  clinic: Clinic | null
  fiscalYears: FiscalYear[] | null
  fiscalYearEdit: FiscalYearParams | null
  selectedYear: FiscalYear | null
  events: CheckupEvent[] | null
  event: CheckupEvent | null
  eventEdit: EventParams | null
  eventEmployees: CheckupEventEmployee[] | null
  eventTargetEmployees: CheckupEventEmployee[] | null
  eventCandidates: CheckupEventCandidate[] | null
  results: CheckupResult[] | null
  resultForm: CheckupResult
  resultEmployees: CheckupResultEmployee[] | null
  resultEmployee: CheckupResultEmployee | null
  resultDocument: CheckupResultDocument | null
  resultClinics: Clinic[] | null
  finalResultEmployees: CheckupFinalResultEmployee[] | null
  finalResultEmployee: CheckupFinalResultEmployeeDetail | null
  finalResultEmployeeEdit: CheckupFinalResultEmployeeParams | null

  paginationClinics: Pagination | null
  paginationEvents: Pagination | null
  paginationEventEmployees: Pagination | null
  paginationEventTargetEmployees: Pagination | null
  paginationEventCandidates: Pagination | null
  paginationResults: Pagination | null
  paginationFinalResultEmployees: Pagination | null

  validateClinicErrors: ValidateError<ClinicParams>[]
  validateFiscalYearErrors: ValidateError<FiscalYearParams>[]
  validateEventErrors: ValidateError<EventParams>[]

  waitingClinics: boolean
  waitingFiscalYears: boolean
  waitingEvents: boolean
  waitingEventEmployees: boolean
  waitingEventTargetEmployees: boolean
  waitingEventCandidates: boolean
  waitingUploadCsv: boolean
  waitingDownloadCsv: boolean
  waitingResultEmployees: boolean
  waitingResultEmployee: boolean
  waitingResults: boolean
  waitingResult: boolean
  waitingResultDocument: boolean
  waitingResultClinics: boolean
  waitingSendMail: boolean
  waitingFinalResultEmployees: boolean
  waitingFinalResultEmployee: boolean

  errorResponseClinics: ImportErrorResponse[] | null
  errorResponseResults: ImportErrorResponse[] | null
}

const initialState: State = {
  clinics: null,
  clinic: null,
  fiscalYears: null,
  fiscalYearEdit: null,
  selectedYear: null,
  events: null,
  event: null,
  eventEdit: null,
  eventEmployees: null,
  eventTargetEmployees: null,
  eventCandidates: null,
  results: null,
  resultForm: initialResult,
  resultEmployees: null,
  resultEmployee: null,
  resultDocument: null,
  resultClinics: null,
  finalResultEmployees: null,
  finalResultEmployee: null,
  finalResultEmployeeEdit: null,
  paginationClinics: null,
  paginationEvents: null,
  paginationEventEmployees: null,
  paginationEventTargetEmployees: null,
  paginationEventCandidates: null,
  paginationResults: null,
  paginationFinalResultEmployees: null,
  validateClinicErrors: [],
  validateFiscalYearErrors: [],
  validateEventErrors: [],
  waitingClinics: false,
  waitingFiscalYears: false,
  waitingEvents: false,
  waitingEventEmployees: false,
  waitingEventTargetEmployees: false,
  waitingEventCandidates: false,
  waitingUploadCsv: false,
  waitingDownloadCsv: false,
  waitingResultEmployees: false,
  waitingResultEmployee: false,
  waitingResults: false,
  waitingResult: false,
  waitingResultDocument: false,
  waitingResultClinics: false,
  waitingSendMail: false,
  waitingFinalResultEmployees: false,
  waitingFinalResultEmployee: false,
  errorResponseClinics: null,
  errorResponseResults: null,
}

const checkup = createSlice({
  name: 'checkup',
  initialState,
  reducers: {
    setWaitingClinics: (state): void => {
      state.waitingClinics = true
    },
    setWaitingFiscalYears: (state): void => {
      state.waitingFiscalYears = true
    },
    setWaitingEvents: (state): void => {
      state.waitingEvents = true
    },
    setWaitingEventEmployees: (state): void => {
      state.waitingEventEmployees = true
    },
    setWaitingEventTargetEmployees: (state): void => {
      state.waitingEventTargetEmployees = true
    },
    setWaitingEventCandidates: (state): void => {
      state.waitingEventCandidates = true
    },
    setWaitingUploadCsv: (state): void => {
      state.waitingUploadCsv = true
    },
    setWaitingDownloadCsv: (state): void => {
      state.waitingDownloadCsv = true
    },
    setWaitingResultEmployees: (state): void => {
      state.waitingResultEmployees = true
    },
    setWaitingResultEmployee: (state): void => {
      state.waitingResultEmployee = true
    },
    setWaitingResults: (state): void => {
      state.waitingResults = true
    },
    setWaitingResult: (state): void => {
      state.waitingResult = true
    },
    setWaitingResultDocument: (state): void => {
      state.waitingResultDocument = true
    },
    setWaitingResultClinics: (state): void => {
      state.waitingResultClinics = true
    },
    setWaitingSendMail: (state): void => {
      state.waitingSendMail = true
    },
    setWaitingFinalResultEmployees: (state): void => {
      state.waitingFinalResultEmployees = true
    },
    setWaitingFinalResultEmployee: (state): void => {
      state.waitingFinalResultEmployee = true
    },
    setClinics: (state, action: PayloadAction<{ clinics: Clinic[] }>): void => {
      state.clinics = action.payload.clinics
    },
    setClinic: (state, action: PayloadAction<{ clinic: Clinic }>): void => {
      state.clinic = action.payload.clinic
    },
    setFiscalYears: (
      state,
      action: PayloadAction<{ fiscalYears: FiscalYear[] }>,
    ): void => {
      state.fiscalYears = action.payload.fiscalYears
      state.waitingFiscalYears = false
    },
    setFiscalYear: (
      state,
      action: PayloadAction<{ fiscalYear: FiscalYear }>,
    ): void => {
      state.fiscalYearEdit = action.payload.fiscalYear
      state.waitingFiscalYears = false
    },
    setSelectedYear: (
      state,
      actions: PayloadAction<{ fiscalYear: FiscalYear }>,
    ): void => {
      state.selectedYear = actions.payload.fiscalYear
    },
    setEvents: (
      state,
      actions: PayloadAction<{ events: CheckupEvent[] }>,
    ): void => {
      state.events = actions.payload.events
      state.waitingEvents = false
    },
    setEvent: (
      state,
      actions: PayloadAction<{ event: CheckupEvent }>,
    ): void => {
      state.event = actions.payload.event
      state.eventEdit = actions.payload.event
      state.waitingEvents = false
    },
    setEventEdit: (
      state,
      actions: PayloadAction<{ eventEdit: EventParams }>,
    ): void => {
      state.eventEdit = actions.payload.eventEdit
    },
    setEventEmployees: (
      state,
      actions: PayloadAction<{ employees: CheckupEventEmployee[] }>,
    ): void => {
      state.eventEmployees = actions.payload.employees
      state.waitingEventEmployees = false
    },
    setEventTargetEmployees: (
      state,
      actions: PayloadAction<{ employees: CheckupEventEmployee[] }>,
    ): void => {
      state.eventTargetEmployees = actions.payload.employees
      state.waitingEventTargetEmployees = false
    },
    setEventCandidates: (
      state,
      actions: PayloadAction<{ employees: CheckupEventCandidate[] }>,
    ): void => {
      state.eventCandidates = actions.payload.employees
      state.waitingEventCandidates = false
    },
    setResultEmployees: (
      state,
      actions: PayloadAction<{ results: CheckupResultEmployee[] }>,
    ): void => {
      state.resultEmployees = actions.payload.results
      state.waitingResultEmployees = false
    },
    setResultEmployee: (
      state,
      actions: PayloadAction<CheckupResultEmployeeData>,
    ): void => {
      state.resultEmployee = actions.payload.checkupSurvey
      state.waitingResultEmployee = false
    },
    setResults: (state, actions: PayloadAction<CheckupResultsData>): void => {
      state.results = actions.payload.checkupSurveys
      state.waitingResults = false
    },
    setResult: (state, actions: PayloadAction<CheckupResultData>): void => {
      state.resultForm = actions.payload.result
      state.waitingResult = false
    },
    setResultDocument: (
      state,
      actions: PayloadAction<CheckupResultDocumentData>,
    ): void => {
      state.resultDocument = actions.payload.document
      state.waitingResultDocument = false
    },
    setResultClinics: (
      state,
      actions: PayloadAction<CheckupResultClinicsData>,
    ): void => {
      state.resultClinics = actions.payload.clinics
    },
    setFinalResultEmployees: (
      state,
      actions: PayloadAction<CheckupFinalResultEmployeesData>,
    ): void => {
      state.finalResultEmployees = actions.payload.checkupSurveys
      state.waitingFinalResultEmployees = false
    },
    setFinalResultEmployee: (
      state,
      actions: PayloadAction<CheckupFinalResultEmployeeData>,
    ): void => {
      state.finalResultEmployee = actions.payload.checkupSurvey
      state.finalResultEmployeeEdit = {
        finalDecideId: state.finalResultEmployee.finalDecideId,
        finalDecideMemo: state.finalResultEmployee.finalDecideMemo,
        finalDecidedUserId: state.finalResultEmployee.finalDecidedUserId,
      }
      state.waitingFinalResultEmployee = false
    },
    changeClinic: (state, action: PayloadAction<Prop<Clinic>>): void => {
      state.clinic = {
        ...state.clinic,
        [action.payload.key]: action.payload.value,
      } as Clinic
    },
    changeFiscalYear: (
      state,
      action: PayloadAction<Prop<FiscalYearParams>>,
    ): void => {
      state.fiscalYearEdit = {
        ...state.fiscalYearEdit,
        [action.payload.key]: action.payload.value,
      } as FiscalYearParams
    },
    changeEvent: (state, actions: PayloadAction<Prop<EventParams>>): void => {
      state.eventEdit = {
        ...state.eventEdit,
        [actions.payload.key]: actions.payload.value,
      } as EventParams
    },
    changeFinalResultEmployeeEdit: (
      state,
      actions: PayloadAction<Prop<CheckupFinalResultEmployeeParams>>,
    ): void => {
      state.finalResultEmployeeEdit = {
        ...state.finalResultEmployeeEdit,
        [actions.payload.key]: actions.payload.value,
      } as CheckupFinalResultEmployeeParams
    },
    setPaginationClinics: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationClinics = actions.payload.pagination
    },
    setPaginationEvents: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationEvents = actions.payload.pagination
    },
    setPaginationEventEmployees: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationEventEmployees = actions.payload.pagination
    },
    setPaginationEventTargetEmployees: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationEventTargetEmployees = actions.payload.pagination
    },
    setPaginationEventCandidates: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ): void => {
      state.paginationEventCandidates = actions.payload.pagination
    },
    setPaginationResults: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationResults = actions.payload.pagination
    },
    setPaginationFinalResultEmployees: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationFinalResultEmployees = actions.payload.pagination
    },
    setValidateClinicErrors: (
      state,
      actions: PayloadAction<ValidateError<ClinicParams>[]>,
    ): void => {
      state.validateClinicErrors = actions.payload
    },
    setValidateFiscalYearErrors: (
      state,
      actions: PayloadAction<ValidateError<FiscalYearParams>[]>,
    ): void => {
      state.validateFiscalYearErrors = actions.payload
    },
    setValidateEventErrors: (
      state,
      actions: PayloadAction<ValidateError<EventParams>[]>,
    ): void => {
      state.validateEventErrors = actions.payload
    },
    setErrorResponseClinics: (
      state,
      actions: PayloadAction<{ errors: ImportErrorResponse[] }>,
    ): void => {
      state.errorResponseClinics = actions.payload.errors
    },
    setErrorResponseResults: (
      state,
      actions: PayloadAction<{ errors: ImportErrorResponse[] }>,
    ): void => {
      state.errorResponseResults = actions.payload.errors
    },
    resetClinics: (state): void => {
      state.clinics = initialState.clinics
    },
    resetClinic: (state): void => {
      state.clinic = initialState.clinic
    },
    resetFiscalYear: (state): void => {
      state.fiscalYearEdit = initialState.fiscalYearEdit
    },
    resetSelectedYear: (state): void => {
      state.selectedYear = initialState.selectedYear
    },
    resetEvent: (state): void => {
      state.event = initialState.event
      state.eventEdit = initialState.eventEdit
    },
    resetEventEdit: (state): void => {
      state.eventEdit = state.event
    },
    resetEventEmployees: (state): void => {
      state.eventEmployees = initialState.eventEmployees
    },
    resetEventTargetEmployees: (state): void => {
      state.eventTargetEmployees = initialState.eventTargetEmployees
    },
    resetEventCandidates: (state): void => {
      state.eventCandidates = initialState.eventCandidates
    },
    resetFinalResultEmployees: (state) => {
      state.finalResultEmployees = initialState.finalResultEmployees
    },
    resetFinalResultEmployee: (state) => {
      state.finalResultEmployee = initialState.finalResultEmployee
    },
    resetValidateClinicErrors: (state): void => {
      state.validateClinicErrors = initialState.validateClinicErrors
    },
    resetValidateFiscalYearErrors: (state): void => {
      state.validateFiscalYearErrors = initialState.validateFiscalYearErrors
    },
    resetValidateEventErrors: (state): void => {
      state.validateEventErrors = initialState.validateEventErrors
    },
    resetWaitingFiscalYears: (state): void => {
      state.waitingFiscalYears = initialState.waitingFiscalYears
    },
    resetWaitingClinics: (state): void => {
      state.waitingClinics = initialState.waitingClinics
    },
    resetWaitingEventEmployees: (state): void => {
      state.waitingEventEmployees = initialState.waitingEventEmployees
    },
    resetWaitingEventTargetEmployees: (state): void => {
      state.waitingEventTargetEmployees =
        initialState.waitingEventTargetEmployees
    },
    resetWaitingEventCandidates: (state): void => {
      state.waitingEventCandidates = initialState.waitingEventCandidates
    },
    resetWaitingUploadCsv: (state): void => {
      state.waitingUploadCsv = initialState.waitingUploadCsv
    },
    resetWaitingDownloadCsv: (state): void => {
      state.waitingDownloadCsv = initialState.waitingDownloadCsv
    },
    resetWaitingResults: (state): void => {
      state.waitingResults = initialState.waitingResults
    },
    resetWaitingResultEmployees: (state): void => {
      state.waitingResultEmployees = initialState.waitingResultEmployees
    },
    resetWaitingResultClinics: (state): void => {
      state.waitingResultClinics = initialState.waitingResultClinics
    },
    resetWaitingSendMail: (state): void => {
      state.waitingSendMail = initialState.waitingSendMail
    },
    resetErrorResponseClinics: (state): void => {
      state.errorResponseClinics = initialState.errorResponseClinics
    },
    resetErrorResponseResults: (state): void => {
      state.errorResponseResults = initialState.errorResponseResults
    },
  },
})

export const actions = checkup.actions
export const checkupReducer = checkup.reducer
