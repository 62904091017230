import { HistoryResultsProps } from '../history/HistoryResults'
import { useAppDispatch, useAppSelector } from '@/stores'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  changeResultMenus,
  updateCheckupResult,
  fetchCheckupResults,
  getInitialMenus,
} from '../../../../services/healthCare/checkup'
import { CheckupResultForm } from '../../../../interfaces/healthCare/checkup'
import { useForm } from 'react-hook-form'
import { fetchClinics } from '../../../../services/healthCare/setting/clinics'

type Options = {
  employeeId: number | undefined
  employmentJudgementId: string | undefined
  birthday?: string
}

type UseCheckupResultHistory = {
  historyResultsProps: HistoryResultsProps
}

export const initialResult = {
  /* 基礎情報判定結果 */
  id: 0,
  employmentJudgmentId: 0,
  fiscalYear: 0,
  eventId: 0,
  employeeId: 0,
  consultedAt: '',
  clinicId: 0,
  clinicCode: '',
  clinic: '',
  /* 健康診断結果 */
  decideTotal: '',
  doctor: '',
  opinion: '',
  height: null,
  weight: null,
  bmi: null,
  abdominalCircumference: null,
  medicalHistory: '',
  smokingHistory: '',
  subjectiveSymptoms: '',
  objectiveSymptoms: '',
  visionNakedRight: null,
  visionNakedLeft: null,
  visionCorrectedRight: null,
  visionCorrectedLeft: null,
  ocularPressureRight: null,
  ocularPressureLeft: null,
  ocularFundusRight: '',
  ocularFundusLeft: '',
  audiometry1000Right: '',
  audiometry1000Left: '',
  audiometry4000Right: '',
  audiometry4000Left: '',
  simpleHearingRight: '',
  simpleHearingLeft: '',
  systolicBloodPressure: null,
  diastolicBloodPressure: null,
  cholesterol: null,
  triglyceride: null,
  ldl: null,
  hdl: null,
  nonHdl: null,
  ast: null,
  alt: null,
  gammaGtp: null,
  alp: null,
  protein: null,
  albumin: null,
  bilirubin: null,
  ldh: null,
  amylase: null,
  hbs: '',
  hbv: '',
  hcv: '',
  uricAcid: null,
  fastingBloodSugar: null,
  hba1c: null,
  proteinInUrine: '',
  sugarInUrine: '',
  occultBloodInUrine: '',
  creatinine: null,
  eGfr: null,
  urinaryRedBloodCell: '',
  urinaryWhiteBloodCell: '',
  epithelialCell: '',
  urinarySediment: null,
  urinaryOthers: '',
  redBloodCell: null,
  whiteBloodCell: null,
  hemoglobin: null,
  hematocrit: null,
  plateletCount: null,
  bloodTyping: '',
  crp: null,
  electrocardiogramOpinion: '',
  electrocardiogramJudgment: '',
  chestXrayOpinion: '',
  chestXrayJudgment: '',
  sputumOpinion: '',
  sputumJudgment: '',
  respiratoryPercentVc: null,
  respiratoryEfv1: null,
  respiratoryPercentEfv1: null,
  upperGastrointestinalOpinion: '',
  upperGastrointestinalJudgment: '',
  abdominalUltrasoundOpinion: '',
  abdominalUltrasoundJudgment: '',
  stoolOccultBlood1: '',
  stoolOccultBlood2: '',
  /* 特定健診問診票 */
  medicineOfHyperTension: '',
  medicineOfBloodSugar: '',
  medicineOfCholesterol: '',
  historyOfStroke: '',
  historyOfHeartDisease: '',
  historyOfKidneyFailure: '',
  historyOfAnemia: '',
  habitOfSmoking: '',
  habitOfGainedWeight: '',
  habitOfMovingExercise: '',
  habitOfLightExercise: '',
  habitOfQuicklyWalking: '',
  habitOfChewing: '',
  habitOfEatInHurry: '',
  habitOfEatInMidnight: '',
  habitOfSnack: '',
  habitOfWithoutBreakfast: '',
  habitOfDrinking: '',
  habitOfDrinkingAmount: '',
  habitOfSleeping: '',
  habitOfImprovement: '',
  habitOfGuidance: '',
  habitOfGuidanceExperience: '',
  /* 基礎情報判定結果 (判定後) */
  medicalDecideId: 0,
  medicalDecide: '',
  workingDecideId: 0,
  workingDecide: '',
  interviewId: 0,
  interview: '',
  afterMeasureStatusId: 0,
  afterMeasureStatus: '',
  memo: '',
  decidedAt: '',
  decidedUserId: 0,
  decidedUserName: '',
  afterMeasureSendAt: '',
  afterMeasureDecideReportId: 0,
  afterMeasureDecideReport: '',
  afterMeasureConsultedAt: '',
  afterMeasureClinic: '',
  afterMeasureOpinionId: 0,
  afterMeasureOpinion: '',
  afterMeasureRestrict: '',
  afterMeasureCheckStatusId: 0,
  afterMeasureCheckStatus: '',
  afterMeasureSystolicBloodPressure: 0,
  afterMeasureDiastolicBloodPressure: 0,
  afterMeasureRepliedAt: '',
  finalResultId: 0,
  finalDecideId: 0,
  finalDecide: '',
  finalDecideMemo: '',
  finalDecidedUserId: 0,
  finalDecidedUserName: '',
  finalDecidedAt: '',
  createdAt: '',
  updatedAt: '',
}

export type CheckupMenu = {
  id: number
  consultedAt: string
  disabled: boolean
  checked: boolean
  decideTarget: boolean
}

export type ResultGroup = 'basic' | 'checkup' | 'consult'

export const useCheckupResultHistory = (
  options: Options,
): UseCheckupResultHistory => {
  const { resultForm, results, clinics } = useAppSelector(
    (state) => state.checkup,
  )
  const dispatch = useAppDispatch()
  const form = useForm<CheckupResultForm>({
    defaultValues: resultForm,
    mode: 'onChange',
  })

  const [menus, setMenus] = useState<CheckupMenu[]>([])
  const [resultGroup, setResultGroup] = useState<ResultGroup>('basic')
  const isError = !!Object.keys(form.formState.errors).length
  const [isEditMode, setIsEditMode] = useState(false)
  const employmentJudgmentId = parseInt(options.employmentJudgementId || '0')

  const initializeForm = useCallback(
    (
      results: CheckupResultForm[],
      employmentJudgmentId: number,
    ): CheckupResultForm => {
      const targetResult = results.find(
        (result: CheckupResultForm) =>
          result.employmentJudgmentId === employmentJudgmentId,
      )
      return (
        targetResult || {
          ...initialResult,
          employmentJudgmentId,
          employeeId: options.employeeId ?? 0,
        }
      )
    },
    [options.employeeId],
  )

  useEffect(() => {
    dispatch(
      fetchCheckupResults(options?.employeeId, options.employmentJudgementId),
    )
    dispatch(fetchClinics())
  }, [dispatch, options?.employeeId, options?.employmentJudgementId])

  useEffect(() => {
    if (options.employmentJudgementId) {
      const initialMenus = getInitialMenus(
        options.employmentJudgementId,
        results,
      )
      setMenus(initialMenus)
    }
  }, [options.employmentJudgementId, results])

  const onChange = useCallback(
    (key: keyof CheckupResultForm, value: string) => {
      form.setValue(key, value)
    },
    [form],
  )

  const showResults = useMemo(() => {
    const resultsArray = results || []

    const filteredResults = resultsArray.filter((result) =>
      menus.some(
        (menu) => menu.id === result.employmentJudgmentId && menu.checked,
      ),
    )

    const validatedResults =
      filteredResults.length > 0
        ? filteredResults
        : [
            {
              ...initialResult,
              employmentJudgmentId: employmentJudgmentId,
            },
          ]
    return validatedResults
  }, [results, menus, employmentJudgmentId])

  useEffect(() => {
    if (results) {
      const initialValues = initializeForm(results, employmentJudgmentId)
      form.reset(initialValues)
    }
  }, [results, employmentJudgmentId, form, initializeForm])

  const onSubmit = form.handleSubmit((formData) => {
    dispatch(updateCheckupResult(formData))
  })

  const toggleEditMode = useCallback(() => {
    if (isEditMode) {
      onSubmit()
    } else {
      // 編集モードがONになった場合、履歴表示を解除する
      const updatedMenus = menus.map((menu) => ({
        ...menu,
        checked: menu.id === employmentJudgmentId ? menu.checked : false,
      }))
      setMenus(updatedMenus)
    }
    setIsEditMode((prevMode) => !prevMode)
  }, [isEditMode, onSubmit, menus, employmentJudgmentId])

  const onChecked = useCallback(
    (id: number) => setMenus((prev) => changeResultMenus(id, prev)),
    [],
  )

  return {
    historyResultsProps: {
      results: showResults,
      clinics,
      form,
      menus,
      isEditMode,
      isError,
      onChange,
      onSubmit,
      toggleEditMode,
      resultGroup,
      setResultGroup,
      onChecked,
      birthday: options.birthday,
      attractId: options.employmentJudgementId
        ? Number.parseInt(options.employmentJudgementId)
        : undefined,
    },
  }
}
