import { ReactNode } from 'react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { theme } from '@/styles/theme'

export type StyleProviderProps = {
  children: ReactNode
}

export const StyleProvider = ({ children }: StyleProviderProps) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>
)
